import { i18n } from "@/main";

/* FORMAT CENTS TO EUROS */
export function formatCentsToEuros(cents: number, locale = "fr-FR"): string {
  const euros = cents / 100;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format(euros);
}

/* FILE EXISTS */
export function fileExists(files: Array<unknown>, key: string) {
  return files.filter((file: unknown) => {
    const typedFile = file as { filename: string };
    const filename = typedFile.filename.startsWith(key);
    if (filename) {
      return filename;
    }
  });
}

export function showError(event?: {
  error: string[] | { message: string };
  errorId: string;
}) {
  if (!event) {
    return;
  }
  let message = i18n.t("error.unknown") as string;

  if (Array.isArray(event.error)) {
    if (
      event.error.length > 0 &&
      (event.error[0] == "Email domain not allowed" ||
        event.error[0] == "Email address with + character not allowed")
    ) {
      message = i18n.t("error.email_not_allowed") as string;
    }
  } else if (
    event.error.message ==
    "Un ou plusieurs IMEI renseigné(s) est (sont) invalides ou a (ont) déjà été utilisé(s)"
  ) {
    message = i18n.t("error.imei_invalid") as string;
  } else if (
    event.error.message == "Vous avez déjà attribué ce numéro client !"
  ) {
    message = i18n.t("error.clientAlreadyExists") as string;
  } else if (event.error.message == "user not found") {
    message = i18n.t("error.imei_invalid") as string;
  } else if (event.error.message == "Compte invalide ou inexistant !") {
    message = i18n.t("error.clientAlreadyExists") as string;
  } else {
    message = event.errorId.toString();
  }

  return message;
}
