import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";

import App from "./App.vue";
import router from "./router";

import "./assets/styles/main.scss";

Vue.use(PiniaVuePlugin);
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import en from "@/locales/en.json";
import fr from "@/locales/fr.json";
import de from "@/locales/de.json";
import es from "@/locales/es.json";
import nl from "@/locales/nl.json";
import it from "@/locales/it.json";
import be from "@/locales/be.json";

const messages = {
  en,
  fr,
  "fr-LU": fr,
  "fr-BE": fr,
  "de-AT": de,
  de,
  es,
  nl,
  it,
  be,
};

export const i18n = new VueI18n({
  locale: "fr",
  messages,
  fallbackLocale: "de",
});

import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

new Vue({
  i18n,
  router,
  pinia: createPinia(),
  render: (h) => h(App),
}).$mount("#app");
