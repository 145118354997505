<script setup lang="ts">
import { ref, defineAsyncComponent, defineProps, watch } from "vue";
import { useOperationStore } from "@/stores/operation";
import LoadingPopupView from "@/components/tools/LoadingPopupView.vue";
const operation = useOperationStore();
const DynamicComponent = ref();
const HeaderComponent = ref();
const FooterComponent = ref();
const props = defineProps({
  file: {
    type: String,
    required: true,
  },
});

const vueIsAvailable = async (
  vueFolder: string,
  idgame: string,
  file: string
) => {
  try {
    await import(`@/idgames/${idgame}/${vueFolder}/${file}.vue`);
    return true;
  } catch (error) {
    return false;
  }
};

const getComponentByDomain = async (
  idgame: string,
  name: string,
  vueFolder: string
) => {
  let folder = idgame;

  const exist = await vueIsAvailable(vueFolder, idgame, name);
  if (!exist) {
    folder = "default";
  }

  return defineAsyncComponent({
    loader: () => {
      return import(`@/idgames/${folder}/${vueFolder}/${name}.vue`);
    },
    loadingComponent: LoadingPopupView,
    delay: 1000,
  });
};

const updateDynamicComponents = async () => {
  DynamicComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? "",
    props.file,
    "views"
  );
  HeaderComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? "",
    "HeaderView",
    "components"
  );

  FooterComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? "",
    "FooterView",
    "components"
  );
};

watch(
  () => props.file,
  () => {
    updateDynamicComponents();
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="content_wrapper"
    v-if="
      operation?.operationData &&
      operation.operationData._id != '6746f70c439fbc9c810444fd'
    "
  >
    <HeaderView
      :is="HeaderComponent"
      v-if="operation?.operationData.operation"
    />
    <transition name="fade" v-if="operation?.operationData.operation">
      <component :is="DynamicComponent" />
    </transition>
    <FooterView
      :is="FooterComponent"
      v-if="operation?.operationData.operation"
    />
  </div>
  <div v-else class="text-center py-48">Maintenance en cours</div>
</template>
