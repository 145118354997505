import { $fetch } from "ofetch";

export const fetch = $fetch.create({
  baseURL:
    process.env.VUE_APP_ROOT_API.toString() ?? "https://gabapi.lucien.ai",
  async onRequest({ options }) {
    options.headers = options.headers || {};
  },
});

export function provideFetch() {
  return {
    fetch,
  };
}
