import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/LayoutView.vue";
import FailView from "@/views/FailView.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      name: "404",
      props: { file: "HomeView" },
      component: FailView,
    },
    {
      path: "/",
      name: "home",
      props: { file: "HomeView" },
      component: HomeView,
    },
    {
      path: "/contact",
      name: "contact",
      props: { file: "ContactView" },
      component: HomeView,
    },
    {
      path: "/register",
      name: "register",
      props: { file: "CreateView" },
      component: HomeView,
    },
    {
      path: "/status/:submissionId/:uid",
      name: "status",
      props: { file: "StatusView" },
      component: HomeView,
    },
    {
      path: "/create/:operationId/:formId",
      name: "create",
      props: { file: "CreateView" },
      component: HomeView,
    },
    {
      path: "/:operationId/contact",
      name: "contact_id",
      props: { file: "ContactView" },
      component: HomeView,
    },
    {
      path: "/:operationId/register",
      alias: "/register2",
      name: "register2",
      props: { file: "CreateView" },
      component: HomeView,
    },
    {
      path: "/:operationId/status/:submissionId/:uid",
      name: "status_id",
      props: { file: "StatusView" },
      component: HomeView,
    },
    {
      path: "/:operationId",
      name: "home_id",
      props: { file: "HomeView" },
      component: HomeView,
    },
  ],
});

export default router;
