<script setup lang="ts">
import { getCurrentInstance, onMounted, ref } from "vue";
import { useOperationStore } from "@/stores/operation";
import { fileExists } from "@/libs/tools";

const operation = useOperationStore();
const currentVueInstance = getCurrentInstance();

const waitForComponent = ref("");

onMounted(async () => {
  try {
    await operation?.getOperation();

    if (operation?.operationData) {
      const lang = operation?.operationData?.langs[0];
      const files = operation?.getFilesData;
      let savedLang = "";

      if (operation?.operationData?.langs.length > 1) {
        savedLang =
          localStorage.getItem("lang") ?? operation?.operationData?.langs[0];
      } else {
        savedLang = operation?.operationData?.langs[0];
      }
      if (
        currentVueInstance &&
        currentVueInstance.proxy &&
        operation &&
        lang !== undefined
      ) {
        currentVueInstance.proxy.$i18n.locale = savedLang ?? lang;

        document.title =
          operation?.operationData?.custom?.pagetitle ?? "......";

        const favicon = fileExists(files as unknown as unknown[], "favicon");
        const linkfav: HTMLLinkElement | null =
          document.querySelector("link[rel='icon']");
        if (linkfav && favicon && favicon.length > 0) {
          linkfav.href = (favicon[0] as { uri: string })?.uri || "/favicon.ico";
        }
      }
    }

    setTimeout(() => {
      waitForComponent.value = "Cet identifiant n'est lié à aucune opération";
    }, 1000);
  } catch (error) {
    console.error(error);
  }
});

import "@/idgames/2024-Tecnifibre-209/assets/styles/main.scss";
import "@/idgames/2024-Jacob_Delafon-227/assets/styles/mains.scss";
</script>

<template>
  <div id="app" :class="`tpl-${operation?.operationData?.idgame}`">
    <router-view v-if="operation?.operationData" />
    <div v-else class="w-full h-full flex justify-center items-center">
      {{ waitForComponent }}
    </div>
  </div>
</template>
